// import { mapGetters } from 'vuex';
import server from '../api/server-common';
import serverMd from '../api/server-model';
import * as constants from '../config/constants';
import elUi from '../elUi';

import CompanyLogo from '../components/CompanyLogo.vue';

export default {
    data: function() {
        return {
            nick: null,
            pass: null,
            mail: null,
            isShowRestorePass: false,
            isShowEmailSended: false,
            isLoading: false,
            isShowPass: false,
        }
    },
    methods: {
        login() {
            if(this.nick && this.nick.trim()) {
                this.isLoading = true;
                server.login({ nick: this.nick.trim(), pass: this.pass }, data => {
                    this.isLoading = false;
                    if(data && data.token) {
                        window.localStorage.setItem(constants.TOKEN_LOCALSTORAGE_NAME, data.token);
                        //TODO: переделать. Идеологически не правильно, надо получать через State.actions...
                        this.$store.commit('setCurrentUser', data);
                        this.$store.dispatch('retriveAllDicts');
                    }
                });
            }
        },
        restorePass() {
            if(this.mail) {
                this.isLoading = true;
                serverMd.saveModel('restorePass', { email: this.mail, locale: this.$i18n.locale }, isSuccess => {
                    this.isLoading = false;
                    if(isSuccess) {
                        this.isShowRestorePass = false;
                        this.isShowEmailSended = true;
                    }
                });
            } else {
                this.$store.commit('addMessageError', this.$t('passChange.emailMismatch'));
            }
        },
        setLocale() {
            this.$i18n.locale = elUi.getLocaleName();
        }
    },
    components: {
        CompanyLogo
    },
    mounted() {
        this.setLocale()
    }
}
